import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import ContactForm from "./ContactForm";
 
export default function DialogContact() {
  const [open, setOpen] = React.useState(false);
 
  const handleOpen = () => setOpen(!open);
 
  return (
    <>
      <Button onClick={handleOpen}  className="bg-black border-white border-2">
        Temasa Geçin
      </Button>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>İletişim Formu</DialogHeader>
        <DialogBody divider>
          <ContactForm />
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>X</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}