import React from 'react';
import ODAK from '../assets/buro.JPG'

export default function Burot() {
  return (
    <div className='container mb-4 mt-5 pt-2 pb-2'>
        <div className="flex flex-col items-center justify-center">
          <div className="border-2 rounded-lg p-2">
           <img src={ODAK} alt="" />
          </div>
        </div>
    </div>
  )
}
