import { Card, Typography } from "@material-tailwind/react";
import React from "react";
import Isg from "../assets/isg.jpg"

export default function IsgDanis() {
  return (
    <div className="container mx-auto">
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src={Isg}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
        İş Sağlığı ve Güvenliği Danışmanlığı: Çalışanlarınızın Güvencesi
        </Typography>
        <Typography color="gray" className="font-normal">
        İş dünyasında, çalışanların sağlığı ve güvenliği her zaman en üst önceliklerden biri olmuştur. İş Sağlığı ve Güvenliği Danışmanlığı, bu önceliği hayata geçiren ve işyerlerinin güvenli bir ortamda faaliyet göstermesini sağlayan önemli bir hizmettir.
        </Typography>

        <Typography color="gray" className="font-normal">
        İş Sağlığı ve Güvenliği Danışmanlığı, uzman danışmanlar tarafından yürütülen bir süreçtir. Amacı, işyerlerinin riskleri değerlendirmesi, güvenlik standartlarına uygunluğu sağlaması ve iş kazalarını en aza indirgemektir. Bu danışmanlık hizmeti, işverenlere ve yöneticilere, mevzuata uygunluğu sağlama, çalışanların eğitimi ve farkındalığı artırma gibi konularda destek sunar.
        </Typography>
        <Typography color="gray" className="font-normal">
        Bu hizmetin sağladığı avantajlar arasında işyeri verimliliğinin artması, iş kazalarının ve hastalıkların azaltılması, işyeri itibarının yükseltilmesi ve yasal yükümlülüklerin yerine getirilmesi yer alır. Ayrıca, çalışanlarınızın sağlığı ve güvenliği için gösterdiğiniz özen, onların motivasyonunu ve bağlılığını artırarak işyeri atmosferini olumlu yönde etkiler.
        </Typography>
        <Typography color="gray" className="font-normal">
        İş Sağlığı ve Güvenliği Danışmanlığı, her sektörde ve her ölçekteki işletme için gereklidir. Çünkü her iş yerinde farklı riskler ve ihtiyaçlar bulunabilir. Bu danışmanlık hizmeti, işyerinizi detaylı bir şekilde analiz eder, riskleri belirler ve uygun stratejiler geliştirerek işyerinizin güvenliğini sağlar.
        </Typography>
        <Typography color="gray" className="font-normal">
        Eğer iş yerinizde çalışanların güvenliği ve sağlığı konusunda endişeleriniz varsa veya mevzuat gerekliliklerini eksiksiz yerine getirmek istiyorsanız, İş Sağlığı ve Güvenliği Danışmanlığı hizmetleri size yol gösterebilir. Çalışanlarınızın iyi bir ortamda çalışmasını sağlamak, işletmenizin sürdürülebilirliği için olmazsa olmazdır. İşte bu noktada, profesyonel danışmanlık hizmetleri işletmenizin en değerli varlığını korumanıza yardımcı olacaktır.
        </Typography>
          
      </div>
    </div>
  );
}
