import React from "react";
import Avatar from "../assets/avatar.jpg";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Tooltip,
} from "@material-tailwind/react";
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import Abt from "../assets/about.jpg";
export default function Hakkimizda() {
  return (
    <div className="container mx-auto p-10">
      {/* text */}
      <div className="about-text ">
        <h2 className="text-center lg:text-[32px] text-[22px] font-bold uppercase mb-2">
          hakkımızda
        </h2>
        <Typography className="font-thin text-[18px] mb-2">
          <i>
            JEOMUT, 2020 yılında Jeoloji Mühendisi Tahir Koldemir tarafından
            kurulan bir jeoloji ve mühendislik firmasıdır. JEOMUT olarak
            stratejik bir yaklaşımla, yenilikçi teknolojilerle ve uzman
            kadromuzla birlikte, doğal kaynakları ve zeminin potansiyelini en
            iyi şekilde değerlendirmek için çalışıyoruz.
          </i>
        </Typography>
        <h3 className="lg:text-[24px] text-[20px] font-bold uppercase mb-2">
          Faaliyet Alanlarımız
        </h3>
        <div className="faaliyet-alan-list mb-2">
          <ul className="gap-y-2">
            <li>
              <b>Sondaj:</b> JEOMUT, sondaj alanında gelişmiş ekipman ve uzman
              personel ile hizmet verir. Jeolojik formasyonların ve zemin
              özelliklerinin belirlenmesi için sondaj çalışmaları düzenler ve
              elde edilen verilere dayanarak detaylı raporlar sunar.
            </li>
            <li>
              <b>Hidrojeolojik Etüt:</b> Su kaynaklarının tespiti, yönetimi ve
              korunması amacıyla hidrojeolojik etütler yapılır. JEOMUT, modern
              tekniklerle yer altı su kaynaklarını araştırarak sürdürülebilir su
              kullanımı için çözümler sunar.
            </li>
            <li>
              <b>Derin Kuyu Arama Ruhsatı:</b> JEOMUT, su ve diğer yer altı
              kaynaklarının elde edilmesi amacıyla derin kuyu arama ruhsatları
              alınması sürecinde müşterilerine danışmanlık sağlar. Mevzuata
              uygunluk ve verimlilik odaklı çalışmalarıyla en uygun kuyu
              alanlarının belirlenmesine yardımcı olur.
            </li>
            <li>
              <b>Derin Kuyu Kullanma Ruhsatı:</b> JEOMUT, su ve diğer yer altı
              kaynaklarının yasal kullanımını sağlamak için derin kuyu kullanma
              ruhsatı başvurularını yapar ve izin süreçlerini yönetir. Mevzuat
              ve çevresel düzenlemeler konusunda uzmanlaşmış ekibiyle,
              müşterilere bu alanda güvenilir ve etkili hizmet sunar.
            </li>
            <li>
              <b>Zemin Etütü:</b> İnşaat projelerinde temel atma aşamasında
              zemin etütleri oldukça önemlidir. JEOMUT, yapıların güvenliğini ve
              dayanıklılığını sağlamak amacıyla zemin etütlerini detaylı bir
              şekilde gerçekleştirir ve raporlarını hazırlar.
            </li>
            <li>
              <b>Zemin Güçlendirme:</b> JEOMUT, zemin etütleri sonucunda tespit
              edilen zayıf zeminlerin güçlendirilmesi için çeşitli teknikler
              uygular. Yerel koşullar ve proje ihtiyaçlarına uygun olarak,
              güvenli ve ekonomik zemin güçlendirme çözümleri sunar.
            </li>
          </ul>
        </div>
        <h3 className="lg:text-[24px] text-[20px] font-bold uppercase mb-2">
          neden JEOMUT?
        </h3>
        <div className="neden-JEOMUT-list mb-2">
          <ul className="gap-y-2">
            <li>
              <b>Uzman ve Deneyimli Ekip:</b> JEOMUT, alanında uzmanlaşmış
              jeoloji mühendisleri ve zemin uzmanlarından oluşan bir ekip
              tarafından yönetilir. Proje odaklı çalışma anlayışı sayesinde
              müşteri ihtiyaçlarına özel çözümler sunar.
            </li>
            <li>
              <b>Teknoloji ve Kalite:</b> JEOMUT, jeoloji ve zemin mühendisliği
              alanında en son teknolojiyi kullanarak yüksek kalitede hizmet
              sunar. Modern ekipmanlar ve analiz yöntemleriyle projelerin
              doğruluğunu ve verimliliğini artırır.
            </li>
            <li>
              <b>Müşteri Memnuniyeti:</b> JEOMUT, müşteri memnuniyetini her
              zaman öncelikli tutar. İhtiyaçları anlamak, süreçleri şeffaf bir
              şekilde iletmek ve zamanında teslimat sağlamak için çaba gösterir.
            </li>
            <li>
              <b>Çevre ve Sürdürülebilirlik:</b> JEOMUT, faaliyetlerini çevreye
              duyarlı bir şekilde yürütür. Sürdürülebilir çözümler sunarak doğal
              kaynakların korunmasına katkıda bulunur.
            </li>
          </ul>
        </div>
        <Typography>
          JEOMUT olarak, müşteri memnuniyetini en üst seviyede tutmak ve uzun
          vadeli iş birlikleri kurmak temel önceliğimizdir. Yenilikçi ve
          çevresel açıdan duyarlı yaklaşımımızla, jeolojik projelerde başarıya
          ulaşmak için sürekli çaba gösteriyoruz. Jeoloji ve hidrojeoloji
          alanındaki derin bilgimiz ve tecrübemizle, müşterilerimize en uygun ve
          etkili çözümleri sunmaya devam edeceğiz.
        </Typography>
        <Typography className="font-bold">
          JEOMUT - Güvenilir Geleceğe Adım Atın!
        </Typography>
      </div>
      {/* team */}
      <div className="flex justify-center mb-4 mt-4">
        <Card className="w-96 rounded-lg">
          <CardHeader floated={false} className="h-80">
            <img src={Avatar} alt="profile-avatar" />
          </CardHeader>
          <CardBody className="text-center">
            <Typography variant="h4" color="blue-gray" className="mb-2">
              Tahir Koldemir
            </Typography>
            <Typography color="blue" className="font-medium" textGradient>
              Kurucu / Jeoloji Mühendisi
            </Typography>
          </CardBody>
          <CardFooter className="flex justify-center gap-7 pt-2">
            <Tooltip content="Like">
              <Link
                to="https://www.facebook.com/jeomut"
                variant="lead"
                className="text-blue-500"
                textGradient
              >
                <FaFacebook />
              </Link>
            </Tooltip>
            <Tooltip content="Follow">
              <Link
                to="https://www.linkedin.com/in/tahir-koldemir-03273261/"
                variant="lead"
                className="text-blue-600"
                textGradient
              >
                <FaLinkedin />
              </Link>
            </Tooltip>
            <Tooltip content="Follow">
              <Link
                to="https://www.instagram.com/jeomut/"
                variant="lead"
                className="text-purple-400"
                textGradient
              >
                <FaInstagram />
              </Link>
            </Tooltip>
          </CardFooter>
        </Card>
      </div>
      <img
        alt="nature"
        className="w-full rounded-lg object-cover object-center mb-4"
        src={Abt}
      />
    </div>
  );
}
