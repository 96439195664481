import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../assets/avatar.jpg";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Tooltip,
} from "@material-tailwind/react";
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";

export default function LittleAbout() {
  return (
    <div id="l-about" className="mt-10 mb-2 pt-10 pb-10 pr-5 pl-5 bg-gray-100">
      {/* ceo */}
      <div className="flex justify-center">
        <Card className="w-96 rounded-lg">
          <CardHeader floated={false} className="h-80">
            <img src={Avatar} alt="profile-avatar" />
          </CardHeader>
          <CardBody className="text-center">
            <Typography variant="h4" color="blue-gray" className="mb-2">
              Tahir Koldemir
            </Typography>
            <Typography color="blue" className="font-medium" textGradient>
              Kurucu / Jeoloji Mühendisi
            </Typography>
          </CardBody>
          <CardFooter className="flex justify-center gap-7 pt-2">
            <Tooltip content="Like">
              <Link
                to="https://www.facebook.com/jeomut"
                variant="lead"
                className="text-blue-500"
                textGradient
              >
                <FaFacebook />
              </Link>
            </Tooltip>
            <Tooltip content="Follow">
              <Link
                to="https://www.linkedin.com/in/tahir-koldemir-03273261/"
                variant="lead"
                className="text-blue-600"
                textGradient
              >
                <FaLinkedin />
              </Link>
            </Tooltip>
            <Tooltip content="Follow">
              <Link
                to="https://www.instagram.com/jeomut/"
                variant="lead"
                className="text-purple-400"
                textGradient
              >
                <FaInstagram />
              </Link>
            </Tooltip>
          </CardFooter>
        </Card>
      </div>
      {/* flex-text */}
      <div className="flex justify-center mt-10">
      <Typography className=" text-gray-900 font-thin text-[16px]">
          <i>JEOMUT, 2020 yılında Jeoloji Mühendisi Tahir Koldemir tarafından kurulan 
          bir jeoloji ve mühendislik firmasıdır. JEOMUT olarak stratejik bir yaklaşımla, yenilikçi teknolojilerle ve uzman kadromuzla birlikte, doğal kaynakları ve zeminin potansiyelini en iyi şekilde değerlendirmek için çalışıyoruz.</i> 
          </Typography>
      </div>
      <div className="flex flex-col mt-10 gap-y-12 lg:flex-row lg:flex-items-center lg:gap-x-12">
        <div className="text-black">
          <span><h4 className="font-bold text-[15px]">Neden JEOMUT?</h4></span>
          <Typography className="font-normal text-[15px]">Uzman ve Deneyimli Ekip: JEOMUT, alanında uzmanlaşmış jeoloji mühendisleri ve zemin uzmanlarından oluşan bir ekip tarafından yönetilir. Proje odaklı çalışma anlayışı sayesinde müşteri ihtiyaçlarına özel çözümler sunar.</Typography>
          <Typography className="font-normal text-[15px]">Teknoloji ve Kalite: JEOMUT, jeoloji ve zemin mühendisliği alanında en son teknolojiyi kullanarak yüksek kalitede hizmet sunar. Modern ekipmanlar ve analiz yöntemleriyle projelerin doğruluğunu ve verimliliğini artırır.</Typography>
        </div>
        <div className="text-black">
          <Typography className="font-normal text-[15px]">Müşteri Memnuniyeti: JEOMUT, müşteri memnuniyetini her zaman öncelikli tutar. İhtiyaçları anlamak, süreçleri şeffaf bir şekilde iletmek ve zamanında teslimat sağlamak için çaba gösterir.</Typography>
          <Typography className="font-normal text-[15px]">Çevre ve Sürdürülebilirlik: JEOMUT, faaliyetlerini çevreye duyarlı bir şekilde yürütür. Sürdürülebilir çözümler sunarak doğal kaynakların korunmasına katkıda bulunur.</Typography>
          <span className="font-bold uppercase text-[17px]">JEOMUT - Güvenilir Geleceğe Adım Atın!</span>
        </div>
      </div>
    </div>
  );
}
