import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import DiaologContact from './DialogContact';
import BgImg from '../assets/rainier.jpg';
 
export default function Hero() {
  return (
    <Card
      shadow={false}
      className="relative grid h-[40rem] w-full mt-6 items-end justify-center overflow-hidden text-center"
    >
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="absolute inset-0 m-0 h-full w-full rounded-none bg-cover bg-center"
        style={{ backgroundImage: `url(${BgImg})` }}
      >
        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
      </CardHeader>
      <CardBody className="relative py-24 px-6 md:px-12">
        <Typography
          variant="h2"
          color="white"
          className="mb-6 leading-[1] lg:uppercase lg:text-lg text-md capitalize"
        >
          Su problemlerinizin çözümü için tıklayınız
        </Typography>
        <DiaologContact />
      </CardBody>
    </Card>
  );
}