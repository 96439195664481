import { Card, Typography } from "@material-tailwind/react";
import React from "react";
import Zetut from "../assets/kuyu.jpg"
export default function ZeminEtut() {
  return (
    <div className="container mx-auto">
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src={Zetut}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
          Zemin Etütü
        </Typography>
        <Typography color="gray" className="font-normal">
          Zemin etütü, inşa edilecek yapıların güvenli temellerini oluşturmak
          için yapılan kritik bir çalışmadır. Yapıların dayanıklılığı ve
          sağlamlığı, temel alındığı zeminin özelliklerine bağlıdır. Bu nedenle,
          zemin etütleri yapıların yerleşim yerlerinin seçimi ve inşa sürecinin
          başarılı bir şekilde gerçekleştirilmesi için hayati bir öneme
          sahiptir.
        </Typography>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          Zemin Etütün Amacı
        </Typography>
        <Typography color="gray" className="font-normal">
          Zemin etütleri, yapılacak inşaat projesinin temel alacağı zeminin
          jeolojik, jeoteknik ve hidrolik özelliklerini incelemek ve analiz
          etmek için yapılan çalışmalardır. Bu etütlerin amacı şunlardır:
          <br />
          <b>Zeminin Taşıma Kapasitesini Belirleme:</b> Zemin etütleri, zeminin
          taşıma kapasitesini ve dayanıklılığını belirleyerek yapı için uygun
          temel sistemini tasarlamaya yardımcı olur.
          <br />
          <b>Zeminin Geoteknik Özelliklerini Değerlendirme:</b> Zeminin
          mukavemeti, sıkışabilirlik ve deformasyon özellikleri gibi jeoteknik
          parametreler, zemin etütleriyle analiz edilir.
          <br />
          <b>Zeminin Sıvılaşma Potansiyelini Belirleme:</b> Zemin etütleri,
          deprem durumunda zeminin sıvılaşma potansiyelini belirleyerek
          yapıların güvenliği için önemli bir rol oynar.
          <br />
          <b>Su Seviyesi ve Hidrolik Koşulların Tespiti:</b> Zemin etütleri,
          zemin altındaki su seviyesini belirleyerek yapıların yerleşimini ve su
          yalıtımı ihtiyacını tespit eder.
          <br />
        </Typography>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          Zemin Etütleri Nasıl Yapılır?
        </Typography>
        <Typography color="gray" className="font-normal">
          Zemin etütleri, saha çalışmaları ve laboratuvar analizleri ile
          gerçekleştirilir. İşte zemin etütlerinin genel adımları:
          <br />
          <b>Saha İncelemesi:</b> Zemin etütleri, inşa edilecek alanın sahada
          incelenmesi ile başlar. Yerel jeolojik ve jeoteknik özellikler
          gözlemlenir ve not alınır.
          <br />
          <b>Numune Alma:</b> Zemin örnekleri alınarak laboratuvara gönderilir.
          Bu numuneler, zeminin fiziksel ve mekanik özelliklerini belirlemek
          için çeşitli deneylere tabi tutulur.
          <br />
          <b>Laboratuvar Analizleri:</b> Zemin numuneleri laboratuvarda çeşitli
          testlere tabi tutulur. Sıkışma testleri, taşıma kapasitesi analizleri,
          su içeriği ve kılcal su yükselmesi gibi testler yapılır.
          <br />
          <b>Sonuçların Raporlanması:</b> Zemin etütlerinin sonuçları, detaylı
          bir rapor halinde sunulur. Bu rapor, yapıların temel tasarımında
          kullanılacak önemli bilgileri içerir.
          <br />
          <br />
          Zemin etütleri, yapıların güvenli ve dayanıklı temellerini oluşturmak
          için yapılan hayati bir çalışmadır. Zemin etütleri sayesinde,
          yapılacak inşaat projesinin uygun temel sistemini tasarlamak, zeminin
          taşıma kapasitesini belirlemek ve deprem veya diğer doğal afetlere
          karşı yapıların güvenliğini sağlamak mümkün olur.
        </Typography>
      </div>
    </div>
  );
}
