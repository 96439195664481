import { Card, Typography } from "@material-tailwind/react";
import React from "react";
import Solarsu from "../assets/solarsu.jpeg"

export default function KuyuKullanma() {
  return (
    <div className="container mx-auto">
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src={Solarsu}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
        Solar Enerji ve Tarımsal Sulama
        </Typography>
        <Typography color="gray" className="font-normal">
        Solar enerji, Güneş'ten elde edilen temiz ve sınırsız bir enerji kaynağıdır. Bu enerjinin tarımsal sulama sistemlerinde kullanılması, çeşitli avantajlar sunar. Geleneksel sulama yöntemlerinde kullanılan elektrik veya fosil yakıtlara kıyasla, güneş enerjisi daha çevre dostu ve ekonomik bir alternatiftir.
          <br />
          <b>Uygulama Alanları</b>
        </Typography>

        <Typography color="gray" className="font-normal">
          <b>Arazi Sulaması:</b> Tarım arazilerinin sulanması, genellikle uzak bölgelerde olduğundan enerji iletim maliyetleri yüksek olabilir. Güneş enerjisi, bu alanlarda çevre dostu ve ekonomik bir enerji kaynağı olarak kullanılabilir.
          <br />
          <b>Seracılık ve Bahçecilik:</b> Özellikle sera alanlarında ve bahçelerde güneş enerjisi ile çalışan sulama sistemleri, bitkilerin ihtiyacına göre su sağlar ve verimliliği artırır.
          <br />
          <b>Damlama Sulama: </b> Güneş enerjisi, damlama sulama sistemlerinde suyun doğrudan bitkilere yönlendirilmesini sağlayarak su israfını minimize eder.
          <br />
          <b>Hayvancılık ve Hayvan Sulama:</b> Tarım sektörü sadece bitkisel üretimle sınırlı değildir. Güneş enerjisi, hayvanların sulama ihtiyacını da karşılamak için kullanılabilir.
          <br />
          <br />
          Tarımsal sulamada solar enerji kullanımı, tarımsal üretimi daha verimli, sürdürülebilir ve ekonomik hale getiren bir adım olarak öne çıkmaktadır. Güneş enerjisi, düşük maliyetleri, çevre dostu yapısı ve enerji bağımsızlığı gibi avantajlarıyla tarım sektörünü dönüştürerek gelecekteki gıda güvencesine katkıda bulunabilir. Bu nedenle, güneş enerjisi destekli tarımsal sulama projeleri, sürdürülebilir tarım uygulamalarının vazgeçilmez bir parçası haline gelmektedir.
        </Typography>
      </div>
    </div>
  );
}
