import React from "react";
import LittleAbout from "../components/LittleAbout";
import References from "../components/References";
import Contact from "../components/Contact";
import Hizmetlerimiz from "../components/Hizmetlerimiz";
import Hero from "../components/Hero";
export default function Anasayfa() {
  return (
    <div>
      <div className="container mx-auto overflow-x-hidden">
        <Hero />
        <Hizmetlerimiz />
        <LittleAbout />
        <References />
      </div>
      <Contact />
    </div>
  );
}
