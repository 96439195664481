import { Card, Typography } from "@material-tailwind/react";
import React from "react";
import Sndj from "../assets/sondaj.jpg"

export default function Sondaj() {
  return (
    <div className="container mx-auto">
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src={Sndj}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
          Sondaj
        </Typography>
        <Typography color="gray" className="font-normal">
          Türkiye, iklim ve coğrafi konumu gereği su kaynaklarının etkin
          kullanımı için özel önem taşıyan bir ülkedir. Tarım, sanayi ve içme
          suyu gibi farklı alanlardaki su ihtiyacını karşılamak amacıyla yeraltı
          sularının keşfi ve kullanımı sondaj yöntemiyle gerçekleştirilir.
          Türkiye'de sondaj yaptırmak, su kaynaklarının verimli ve
          sürdürülebilir kullanımı için atılan önemli bir adımdır. <br />
          Türkiye, iklimsel farklılıkların görüldüğü bir ülke olup, su
          kaynaklarının dağılımı düzensizdir. Bu nedenle, bazı bölgelerde su
          ihtiyacı oldukça yüksekken, diğer bölgelerde ise su kıtlığı
          yaşanabilir. Bu durumda, su kaynaklarının etkin şekilde keşfedilmesi
          ve kullanılması büyük bir önem taşır. Sondaj yöntemi, yeraltı
          sularının tespit edilmesi ve su ihtiyacının karşılanmasında hayati bir
          rol oynar.
        </Typography>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          Sondaj Yaptırmak için İzin ve Ruhsat Süreci
        </Typography>
        <Typography color="gray" className="font-normal">
          Türkiye'de sondaj yapmak isteyenler, belirli düzenlemelere ve izinlere
          tabi tutulurlar. Sondaj yapılacak bölgede çevresel ve su kaynaklarının
          korunması göz önünde bulundurularak, Çevre ve Şehircilik Bakanlığı
          tarafından izin ve ruhsat verilir. Bu süreçte, sondajın yapılacağı
          bölgenin teknik ve çevresel açıdan uygunluğu dikkate alınır. Böylece,
          su kaynakları verimli ve sürdürülebilir bir şekilde kullanılırken,
          çevresel etkiler de minimum düzeyde tutulur.
        </Typography>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          Sondajın Kullanım Alanları
        </Typography>
        <Typography color="gray" className="font-normal">
          Türkiye'de sondaj yöntemi, çeşitli alanlarda su ihtiyacını karşılamak
          için kullanılır:
          <br />
          <b>Tarımsal Sulama:</b> Tarım, Türkiye ekonomisinin önemli bir
          parçasıdır ve verimli tarım alanları oluşturmak için sulama önemli bir
          rol oynar. Sondajlar, tarım arazilerine su temin ederek verimliliği
          artırır.
          <br />
          <b>Endüstriyel Kullanım:</b> Sanayi sektörü, su ihtiyacı yüksek olan
          bir alandır. Sondajlar, sanayi tesislerine yeterli ve sürekli su
          kaynağı sağlamak için kullanılır.
          <br />
          <b>İçme Suyu Temini:</b> Bazı bölgelerde içme suyu kaynakları sınırlı
          olabilir. Sondaj yöntemi, içme suyu temini için alternatif su
          kaynaklarının keşfedilmesinde kullanılır.
          <br />
          <b>Jeotermal Enerji:</b> Türkiye, jeotermal enerji potansiyeli yüksek
          bir ülkedir. Sondajlar, jeotermal enerji kaynaklarının keşfi ve
          kullanımı için önemlidir.
          <br />
          <br />
          Sondaj yaptırmak, su kaynaklarının keşfi ve kullanımında önemli bir
          adımdır. Verimli tarım alanları oluşturmak, sanayi su ihtiyacını
          karşılamak, içme suyu sağlamak ve jeotermal enerji kaynaklarını
          keşfetmek için sondaj yöntemi hayati bir rol oynar. Çevresel ve su
          kaynaklarının korunmasına yönelik alınan izinler ve ruhsatlar
          sayesinde su kaynakları sürdürülebilir ve etkin bir şekilde
          kullanılarak kalkınmaya da katkı sağlanır.
        </Typography>
      </div>
    </div>
  );
}
