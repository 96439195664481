import React from 'react';
import ImgTse from '../assets/tse.JPG'

export default function Tse() {
  return (
    <div className='container mb-4 mt-5 pt-2 pb-2'>
        <div className="flex flex-col items-center justify-center">
         <div className="border-2 rounded-lg p-2">
          <img src={ImgTse} alt="" />
         </div>
        </div>
    </div>
  )
}
