import React from 'react'
import TEKN from '../assets/teknikSorumlu.JPG'

export default function Teknik() {
  return (
    <div className='container mb-4 mt-5 pt-2 pb-2'>
        <div className="flex flex-col items-center justify-center">
         <div className="border-2 rounded-lg p-2">
           <img src={TEKN} alt="" />
         </div>
        </div>
    </div>
  )
}
