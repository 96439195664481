import { Card, Typography } from "@material-tailwind/react";
import React from "react";
import Zguc from "../assets/zeminguc.jpeg"

export default function ZeminGuc() {
  return (
    <div className="container mx-auto">
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src={Zguc}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
          Zemin Güçlendirme
        </Typography>
        <Typography color="gray" className="font-normal">
          Zemin güçlendirme, doğal zemin koşullarının yetersiz olduğu veya
          zamanla zayıflayan zeminlerin, yapıların üzerine veya içine eklenen
          çeşitli mühendislik teknikleri ve yapısal elemanlarla güçlendirilmesi
          işlemidir. Bu yöntemler, mevcut zeminin taşıma kapasitesini artırmak,
          sıvılaşma potansiyelini azaltmak, yerleşme problemlerini düzeltmek
          veya deprem etkilerine karşı yapıların dayanıklılığını artırmak için
          kullanılır. Zemin güçlendirme, yapıların daha güvenli ve
          sürdürülebilir hale getirilmesine katkı sağlar.
        </Typography>
      </div>
    </div>
  );
}
