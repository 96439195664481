import { Card, Typography } from "@material-tailwind/react";
import React from "react";
import Kuyu from "../assets/kuyu.jpg"

export default function KuyuArama() {
  return (
    <div className="container mx-auto">
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src={Kuyu}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
          Derin Kuyu Arama Ruhsatı
        </Typography>
        <Typography color="gray" className="font-normal">
          Derin kuyu arama ruhsatı, Türkiye'de yeraltı sularının keşfi ve
          kullanımı amacıyla yapılan sondaj çalışmaları için verilen bir izin ve
          ruhsat türüdür. Sondaj yöntemiyle yeraltı su kaynaklarının
          belirlenmesi ve kullanılabilirliğinin değerlendirilmesi, su
          kaynaklarının etkin ve sürdürülebilir bir şekilde yönetilmesi için
          büyük önem taşır. Bu nedenle, derin kuyu arama ruhsatı, su
          kaynaklarının verimli ve etkin bir şekilde kullanılmasını sağlamak
          üzere çeşitli düzenlemelere tabi tutulmuş bir süreçtir. <br />
          Derin kuyu arama ruhsatı alabilmek için, arama yapılacak bölgenin
          uygunluğu ve çevresel etkilerin değerlendirilmesi gibi belirli
          kriterlere uygunluk göstermek gerekmektedir. Bu kriterler, Çevre ve
          Şehircilik Bakanlığı ve ilgili diğer kamu kurumları tarafından
          belirlenir ve izin süreci bu kriterlere uygun olarak yürütülür.
          <br />
          Derin kuyu arama ruhsatı için izin süreci genellikle şu adımları
          içerir:
        </Typography>

        <Typography color="gray" className="font-normal">
          <b>Başvuru ve Proje Sunumu:</b> Sondaj yapmak isteyen kişi veya
          kuruluş, arama yapmayı planladıkları bölgedeki ilgili Çevre ve
          Şehircilik Müdürlüğü'ne başvuruda bulunur. Başvuruda, arama yapılacak
          bölgenin teknik ve çevresel açıdan uygunluğunu gösteren bir proje
          sunulur.
          <br />
          <b>Teknik ve Bilimsel Değerlendirme:</b> Başvuru sürecinde, proje
          uzmanlar ve bilirkişiler tarafından teknik ve bilimsel açıdan
          değerlendirilir. Yeraltı su kaynaklarının potansiyeli ve çevresel
          etkileri incelenir.
          <br />
          <b>Çevresel Etki Değerlendirmesi (ÇED) Raporu:</b> Bazı durumlarda,
          proje için Çevresel Etki Değerlendirmesi (ÇED) raporu hazırlanması
          gerekebilir. Bu rapor, proje alanının çevresel etkilerinin detaylı bir
          şekilde değerlendirilmesini sağlar.
          <br />
          <b>Ruhsat Verilmesi:</b> Proje ve başvuru süreci değerlendirildikten
          sonra, uygun bulunan projeye derin kuyu arama ruhsatı verilir. Ruhsat
          sahibi, belirli süre ve koşullar altında derin kuyu arama
          çalışmalarını gerçekleştirme hakkına sahip olur.
          <br />
          <br />
          Derin kuyu arama ruhsatı, su kaynaklarının etkin kullanımı ve
          sürdürülebilir yönetimi için oldukça önemli bir adımdır. Ruhsat
          sahibi, belirlenen süre içinde derin kuyu arama çalışmalarını
          yürüterek, yeraltı su kaynaklarının keşfini ve kullanımını sağlar. Bu
          süreçte çevresel etkilerin minimum düzeyde tutulması ve su
          kaynaklarının korunması büyük önem taşır. Bu nedenle, derin kuyu arama
          ruhsatı verilirken çevre ve su kaynaklarının korunmasına yönelik
          titizlikle değerlendirme yapılır.
        </Typography>
      </div>
    </div>
  );
}
