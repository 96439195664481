import React from 'react';
import AS from '../assets/as.JPG';
import BS from '../assets/bs.JPG';
import CS from '../assets/cs.JPG';
export default function IsSag() {
  return (
    <div className='container mb-4 mt-5 pt-2 pb-2'>
        <div className="flex flex-col items-center justify-center gap-y-6">
         <div className="border-2 rounded-lg p-2">
          <img src={AS} alt="" />
         </div>
         <div className="border-2 rounded-lg p-2">
          <img src={BS} alt="" />
         </div>
         <div className="border-2 rounded-lg p-2">
          <img src={CS} alt="" />
         </div>
        </div>
    </div>
  )
}
