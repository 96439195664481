import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { NavbarWithMegaMenu } from './components/NavbarWithMegaMenu';
import { Footer } from './components/Footer';
// pages
import Anasayfa from './pages/Anasayfa';
import Hakkimizda from './pages/Hakkimizda';
import Iletisim from './pages/Iletisim';
import Sondaj from './pages/Sondaj';
import Hidrojeoloji from './pages/Hidrojeoloji';
import KuyuArama from './pages/KuyuArama';
import KuyuKullanma from './pages/KuyuKullanma';
import ZeminEtut from './pages/ZeminEtut';
import ZeminGuc from './pages/ZeminGuc';
import Teknik from './pages/Teknik';
import OdaKayit from './pages/OdaKayit';
import Tse from './pages/Tse';
import IsSag from './pages/IsSag';
import Solar from './pages/Solar';
import IsgDanis from './pages/IsgDanis'
import Burot from './pages/Burot';


export default function App() {
  return (
  <BrowserRouter>
  <NavbarWithMegaMenu />
  <Routes>
    <Route path='/'>
      <Route index element={<Anasayfa />} />
      <Route path='hakkimizda' element={<Hakkimizda />} />
      <Route path='iletisim' element={<Iletisim />} />
      <Route path='sondaj' element={<Sondaj />} />
      <Route path='hidrojeolojiketut' element={<Hidrojeoloji />} />
      <Route path='derinkuyuarama' element={<KuyuArama />} />
      <Route path='derinkuyukullanma' element={<KuyuKullanma />} />
      <Route path='zeminetutu' element={<ZeminEtut />} />
      <Route path='zeminguclendirme' element={<ZeminGuc />} />
      <Route path='teknik_sorumluluk' element={<Teknik />} />
      <Route path='tse_iso' element={<Tse />} />
      <Route path='oda_kayit' element={<OdaKayit />} />
      <Route path='issagligi_güvenligi' element={<IsSag />} />
      <Route path='solar' element={<Solar />} />
      <Route path='isg-danismanlik' element={<IsgDanis />} />
      <Route path='buro-tescil' element={<Burot />} />
    </Route>
  </Routes>
  <Footer />
  </BrowserRouter>
  )
}
