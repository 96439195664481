import React from "react";
import { Card } from "@material-tailwind/react";

const Cards = (props) => {
  return (
    <Card className="shadow-lg w-52 rounded-xl">
      <img src={props.imageURL} alt="Card" />
    </Card>
  );
};

export default function References() {
  const cardData = [
    {
      id: 1,
      imageURL: require("../assets/logolar/1.png"),
    },
    {
      id: 2,
      imageURL: require("../assets/logolar/2.png"),
    },
    {
      id: 3,
      imageURL: require("../assets/logolar/3.png"),
    },
    {
      id: 4,
      imageURL: require("../assets/logolar/4.png"),
    },
    {
      id: 5,
      imageURL: require("../assets/logolar/5.png"),
    },
    {
      id: 6,
      imageURL: require("../assets/logolar/6.png"),
    },
    {
      id: 7,
      imageURL: require("../assets/logolar/7.png"),
    },
    {
      id: 8,
      imageURL: require("../assets/logolar/8.png"),
    },
    {
      id: 9,
      imageURL: require("../assets/logolar/9.png"),
    },
    {
      id: 10,
      imageURL: require("../assets/logolar/10.png"),
    },
    {
      id: 11,
      imageURL: require("../assets/logolar/11.png"),
    },
    {
      id: 12,
      imageURL: require("../assets/logolar/12.png"),
    },
    // Diğer kartlar buraya eklenebilir...
  ];
  return (
    <div className="border-t-2">
      <span className="absolute text-center left-0 right-0 uppercase text-[38px] lg:text-[70px] font-extrabold -z-5 text-gray-100">Referanslarımız</span>
      <h2 className="relative text-center uppercase font-bold text-[22px] text-gray-700 lg:text-[36px] lg:mt-10 mt-6 mb-6">
        Referanslarımız
      </h2>
      <div className="flex flex-wrap items-center  border-b-2 p-10 gap-x-6 gap-y-4 justify-center mb-10">
      {cardData.map((card) => (
        <Cards key={card.id} imageURL={card.imageURL} />
      ))}
    </div>
    </div>
  );
}
