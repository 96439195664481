import React from "react";
import Logo from '../assets/jlogo.jpg'
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  Button,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChatBubbleBottomCenterIcon,
  ChevronDownIcon,
  UserCircleIcon,
  HomeIcon,
  Bars3Icon,
  XMarkIcon,
  ArrowDownOnSquareIcon,
  GlobeAltIcon,
  MagnifyingGlassCircleIcon,
  Square3Stack3DIcon,
  KeyIcon,
  PencilSquareIcon,
  AdjustmentsVerticalIcon,
  SunIcon,
  FireIcon,
} from "@heroicons/react/24/outline";
import{FaFacebook, FaInstagram} from 'react-icons/fa';
import { Link } from "react-router-dom";
 
const colors = {
  blue: "bg-blue-50 text-blue-500",
  orange: "bg-orange-50 text-orange-500",
  green: "bg-green-50 text-green-500",
  "blue-gray": "bg-blue-gray-50 text-blue-gray-500",
  purple: "bg-purple-50 text-purple-500",
  teal: "bg-teal-50 text-teal-500",
  cyan: "bg-cyan-50 text-cyan-500",
  pink: "bg-pink-50 text-pink-500",
};

const navBelgeMenuItems = [
  {
    color: "orange",
    url: "/teknik_sorumluluk",
    icon: ArrowDownOnSquareIcon ,
    title: "Teknik Sorumlu Jeoloji Mühendisi",
  },
  {
    color: "blue",
    url: "/tse_iso",
    icon: GlobeAltIcon ,
    title: "TSE ISO 9001,14001,18001",
  },
  {
    color: "cyan",
    url: "/oda_kayit",
    icon: UserCircleIcon ,
    title: "Oda Kayıt"
  },
  {
    color: "green",
    url: "/issagligi_güvenligi",
    icon: Bars3Icon ,
    title: "İş Sağlığı ve Güvenliği"
  },
  {
    color: "purple",
    url:"/buro-tescil",
    icon:Square3Stack3DIcon,
    title:"Büro Tescil Belgesi"

  }
  
]

const navListMenuItems = [
  {
    color: "orange",
    url: "/sondaj",
    icon: ArrowDownOnSquareIcon ,
    title: "Sondaj",
  },

  {
    color: "blue",
    url: "/hidrojeolojiketut",
    icon: GlobeAltIcon ,
    title: "Hidrojeolojik Etüt",
  },
  {
    color: "blue-gray",
    url: "/derinkuyuarama",
    icon: MagnifyingGlassCircleIcon,
    title: "Derin Kuyu Arama Ruhsatı",
  },
  {
    color: "purple",
    url: "/derinkuyukullanma",
    icon: KeyIcon,
    title: "Derin Kuyu Kullanma Ruhsatı",
  },
  {
    color:"orange",
    url: "/solar",
    icon: SunIcon,
    title: "Solar Sistemler",
  },
  {
    color: "teal",
    url: "/zeminetutu",
    icon: PencilSquareIcon,
    title: "Zemin Etütü",
  },
  {
    color: "cyan",
    url: "/zeminguclendirme",
    icon: AdjustmentsVerticalIcon,
    title: "Zemin Güçlendirme",
  },
  {
    color:"orange",
    url:"/isg-danismanlik",
    icon: FireIcon,
    title:"İş Sağlığı ve Güvenliği Danışmanlığı"
  }

];
 
function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
 
  const renderItems = navListMenuItems.map(
    ({ icon, title, color, url }, key) => (
      <Link to={url} key={key}>
        <MenuItem className="flex items-center hover:bg-gray-500 gap-2 rounded-lg">
          <div className={`rounded-lg p-5  ${colors[color]}`}>
            {React.createElement(icon, {
              strokeWidth: 2,
              className: "h-6 w-6",
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              color="blue-gray"
              className="flex items-center text-sm"
            >
              {title}
            </Typography>
          </div>
        </MenuItem>
      </Link>
    )
  );
 
  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-normal">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              <Square3Stack3DIcon className="h-[18px] w-[18px]" />
              Hizmetlerimiz
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden bg-white/90 max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-4 gap-y-2">{renderItems}</ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

function BelgeListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
 
  const renderItems = navBelgeMenuItems.map(
    ({ icon, title, color, url }, key) => (
      <Link to={url} key={key}>
        <MenuItem className="flex items-center hover:bg-gray-500 gap-2 rounded-lg">
          <div className={`rounded-lg p-5  ${colors[color]}`}>
            {React.createElement(icon, {
              strokeWidth: 2,
              className: "h-6 w-6",
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              color="blue-gray"
              className="flex items-center text-sm"
            >
              {title}
            </Typography>
          </div>
        </MenuItem>
      </Link>
    )
  );
 
  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-normal">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              <Square3Stack3DIcon className="h-[18px] w-[18px]" />
              Belgelerimiz
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden bg-white/90 max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-2 gap-y-2">{renderItems}</ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}
 
function NavList() {
  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      <Link
        to="/"
        variant="small"
        color="blue-gray"
        className="font-normal"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          <HomeIcon className="h-[18px] w-[18px]" />
          Ana Sayfa
        </ListItem>
      </Link>
      <NavListMenu />
      <Link
        to="/hakkimizda"
        variant="small"
        color="blue-gray"
        className="font-normal"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          <UserCircleIcon className="h-[18px] w-[18px]" />
          Hakkımızda
        </ListItem>
      </Link>
      <BelgeListMenu />
      <Link
        to="/iletisim"
        variant="small"
        color="blue-gray"
        className="font-normal"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          <ChatBubbleBottomCenterIcon className="h-[18px] w-[18px]" />
          İletişim
        </ListItem>
      </Link>
    </List>
  );
}
 
export function NavbarWithMegaMenu() {
  const [openNav, setOpenNav] = React.useState(false);
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);
 
  return (
    <Navbar className="mx-auto sticky top-0 z-10 max-w-screen-xl px-4 py-2">
      <div className="flex items-center justify-between text-blue-gray-900">
        <Link
          to="/"
          variant="h6"
          className="mr-4 cursor-pointer py-1.5 lg:ml-2"
        >
          <img src={Logo} alt="logo-img" className="rounded-sm h-[35px]" />
        </Link>
        <div className="hidden lg:block">
          <NavList />
        </div>
        <div className="hidden gap-2 lg:flex">
        <Button className="bg-blue-gray" size="sm">
            <Link to="https://www.facebook.com/jeomut" className="text-[17px] text-blue-600"><FaFacebook /></Link>
          </Button>
          <Button className="bg-blue-gray"  size="sm">
            <Link to="https://www.instagram.com/jeomut/" className="text-indigo-600 text-[17px]"><FaInstagram /></Link>
          </Button>
        </div>
        <IconButton
          variant="text"
          color="blue-gray"
          className="lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList />
        <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden">
          <Button className="bg-blue-gray" size="sm">
            <Link to="https://www.facebook.com/jeomut" className="text-[17px] text-blue-600"><FaFacebook /></Link>
          </Button>
          <Button className="bg-blue-gray"  size="sm">
            <Link to="https://www.instagram.com/jeomut/" className="text-indigo-600 text-[17px]"><FaInstagram /></Link>
          </Button>
        </div>
      </Collapse>
    </Navbar>
  );
}