import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

const Cards = (props) => {
  return (
    <Card className="mt-6 lg:w-96 shadow-lg hover:bg-gray-800 hover:text-white rounded-xl border-2">
      <CardBody>
        <Typography variant="h5" className="text-center mb-2 font-bold">{props.title}</Typography>
        <Typography>
          {props.desctription}
        </Typography>
      </CardBody>
      <CardFooter className="bottom-0 mt-2 w-full">
        <Link
          to={props.link}
          className="btn p-2 w-full text-center text-white rounded-md bg-black hover:bg-gray-800 mb-2"
        >
          Detay
        </Link>
      </CardFooter>
    </Card>
  );
};

export default function Hizmetlerimiz() {
  const cardData = [
    {
      id: 1,
      title: "Sondaj",
      desctription:
        "JEOMUT, sondaj alanında gelişmiş ekipman ve uzman personeli ile jeolojik formasyonların ve zemin özelliklerinin belirlenmesi için sondaj çalışmaları düzenler ve elde edilen verilere dayanarak detaylı raporlar sunar.",
      link: "/sondaj",
    },
    {
      id: 2,
      title: "Hidrojeolojik Etütler",
      desctription:
        "Su kaynaklarının tespiti, yönetimi ve korunması amacıyla hidrojeolojik etütler yapılır. JEOMUT, modern tekniklerle yer altı su kaynaklarını araştırarak sürdürülebilir su kullanımı için çözümler sunar.",
      link: "/hidrojeolojiketut",
    },
    {
      id: 3,
      title: "Derin Kuyu Arama Ruhsatı",
      desctription:
        "JEOMUT, su ve diğer yer altı kaynaklarının elde edilmesi amacıyla derin kuyu arama ruhsatları alınması sürecinde müşterilerine danışmanlık sağlar.                                                               ",
      link: "/derinkuyuarama",
    },
    {
      id: 4,
      title: "Derin Kuyu Kullanma Ruhsatı",
      desctription:
        "JEOMUT, su ve diğer yer altı kaynaklarının yasal kullanımını sağlamak için derin kuyu kullanma ruhsatı başvurularını yapar ve izin süreçlerini yönetir.",
      link: "/derinkuyukullanma",
    },
    {
      id: 5,
      title: "Zemin Etütü",
      desctription:
        "İnşaat projelerinde temel atma aşamasında zemin etütleri oldukça önemlidir. JEOMUT, yapıların güvenliğini ve dayanıklılığını sağlamak amacıyla zemin etütlerini detaylı bir şekilde gerçekleştirir ve raporlarını hazırlar.",
      link: "/zeminetutu",
    },
    {
      id: 6,
      title: "Zemin Güçlendirme",
      desctription:
        "JEOMUT, zemin etütleri sonucunda tespit edilen zayıf zeminlerin güçlendirilmesi için yerel koşullar ve proje ihtiyaçlarına uygun olarak, güvenli ve ekonomik zemin güçlendirme çözümleri sunar.",
      link: "/zeminguclendirme",
    },
    {
      id: 7,
      title: "Solar Sistemler",
      desctription:"Solar enerji, Güneş'ten elde edilen temiz ve sınırsız bir enerji kaynağıdır. Bu enerjinin tarımsal sulama sistemlerinde kullanılması, çeşitli avantajlar sunar. JEOMUT solar enerji projeleriniz için en güvenilir çözüm ortağınız olarak hizmet vermektedir.",
      link:"/solar"
    },
    {
      id:8,
      title:"İş Sağlığı ve Güvenliği",
      desctription:"Eğer iş yerinizde çalışanların güvenliği ve sağlığı konusunda endişeleriniz varsa veya mevzuat gerekliliklerini eksiksiz yerine getirmek istiyorsanız, JEOMUT İş Sağlığı ve Güvenliği Danışmanlığı hizmetleri size yol gösterebilir.",
      link:"/isg-danismanlik"
    },

    // Diğer kartlar buraya eklenebilir...
  ];
  return (
    <div className="flex flex-wrap items-center border-t-2 border-b-2 lg:p-10 gap-x-6 gap-y-4 justify-center mb-10">
      {cardData.map((card) => (
        <Cards
          key={card.id}
          imageURL={card.imageURL}
          title={card.title}
          desctription={card.desctription}
          link={card.link}
        />
      ))}
    </div>
  );
}
