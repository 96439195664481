import { Card, Typography } from "@material-tailwind/react";
import React from "react";
import Kuyu from "../assets/derin.jpg"

export default function KuyuKullanma() {
  return (
    <div className="container mx-auto">
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src={Kuyu}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
          Derin Kuyu Kullanma Ruhsatı
        </Typography>
        <Typography color="gray" className="font-normal">
          Derin kuyu kullanma ruhsatı, Türkiye'de derin kuyuların açılması ve
          kullanılması için verilen resmi bir izin belgesidir. Derin kuyu
          kullanma ruhsatı, yeraltı su kaynaklarına erişim ve kullanım hakkı
          sağlayarak, su ihtiyacını karşılamak amacıyla kuyu açan kişi veya
          kuruluşlara verilir. Bu ruhsat, yeraltı suyunun etkin ve
          sürdürülebilir bir şekilde kullanılması ve yönetilmesini sağlamak
          üzere çeşitli düzenlemelere tabi tutulmuş bir süreçtir. <br />
          Derin kuyu kullanma ruhsatı alabilmek için, kuyunun açılacağı bölgenin
          uygunluğu ve çevresel etkilerin değerlendirilmesi gibi belirli
          kriterlere uygunluk göstermek gerekmektedir. Bu kriterler, Çevre ve
          Şehircilik Bakanlığı ve ilgili diğer kamu kurumları tarafından
          belirlenir ve izin süreci bu kriterlere uygun olarak yürütülür.
          <br />
          Derin kuyu kullanma ruhsatı için izin süreci genellikle şu adımları
          içerir:
        </Typography>

        <Typography color="gray" className="font-normal">
          <b>Başvuru ve Proje Sunumu:</b> Kuyu açmak isteyen kişi veya kuruluş,
          kuyunun açılacağı bölgedeki ilgili Çevre ve Şehircilik Müdürlüğü'ne
          başvuruda bulunur. Başvuruda, kuyunun teknik özellikleri, kullanım
          amacı ve çevresel etkileri hakkında detaylı bilgi sunulur.
          <br />
          <b>Teknik ve Bilimsel Değerlendirme:</b> Başvuru sürecinde, kuyu
          projesi uzmanlar ve bilirkişiler tarafından teknik ve bilimsel açıdan
          değerlendirilir. Kuyunun derinliği, su taşıyıcı formasyonlar, su
          kalitesi gibi önemli parametreler incelenir.
          <br />
          <b>Çevresel Etki Değerlendirmesi (ÇED) Raporu:</b> Bazı durumlarda,
          kuyu açma projesi için Çevresel Etki Değerlendirmesi (ÇED) raporu
          hazırlanması gerekebilir. Bu rapor, kuyunun çevresel etkilerinin
          detaylı bir şekilde değerlendirilmesini sağlar.
          <br />
          <b>Ruhsat Verilmesi:</b> Proje ve başvuru süreci değerlendirildikten
          sonra, uygun bulunan kuyu projesine derin kuyu kullanma ruhsatı
          verilir. Ruhsat sahibi, belirli süre ve koşullar altında kuyuyu
          kullanma hakkına sahip olur.
          <br />
          <br />
          Derin kuyu kullanma ruhsatı, su kaynaklarının etkin kullanımı ve
          sürdürülebilir yönetimi için hayati bir adımdır. Ruhsat sahibi,
          belirlenen süre içinde derin kuyuyu kullanarak yeraltı su kaynağını
          ihtiyaçlarına uygun bir şekilde kullanır. Bu süreçte çevresel
          etkilerin minimum düzeyde tutulması ve su kaynaklarının korunması
          büyük önem taşır. Bu nedenle, derin kuyu kullanma ruhsatı verilirken
          çevre ve su kaynaklarının korunmasına yönelik titizlikle değerlendirme
          yapılır.
        </Typography>
      </div>
    </div>
  );
}
