import React from 'react'
import Contact from '../components/Contact'

export default function Iletisim() {
  return (
    <div className='lg:container mx-auto lg:mt-4 mb-0'>
      <Contact />
    </div>
  )
}
