import { Typography } from "@material-tailwind/react";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import Logo from '../assets/jlogo.jpg'
import { Link } from "react-router-dom";
export function Footer() {
  const date = new Date().getFullYear();
  return (
    <footer className="w-full border-t-2 p-8">
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 text-center md:justify-between">
        <img src={Logo} alt="logo-ct" className="w-40" />
        <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
          <li>
            <Link
              to="/"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              Ana Sayfa
            </Link>
          </li>
          <li>
            <Link
              to="/hakkimizda"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              Hakkımızda
            </Link>
          </li>
          <li>
            <Link
              to="/iletisim"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              İletişim
            </Link>
          </li>
          <li>
            <Link
              to="https://www.facebook.com/jeomut"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              <FaFacebook />
            </Link>
          </li>
          <li>
            <Link
              to="https://www.linkedin.com/in/tahir-koldemir-03273261/"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              <FaLinkedin />
            </Link>
          </li>
          <li>
            <Link
              to="https://www.instagram.com/jeomut/"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              <FaInstagram />
            </Link>
          </li>
        </ul>
      </div>
      <hr className="my-8 border-blue-gray-50" />
      <Typography color="blue-gray" className="text-center font-normal">
        Copyright &copy; {date} JEOMUT, <small> designed by <Link to="http://tunahantatli.com" className="btn text-black">Tunahan Tatlı</Link></small>
      </Typography>
    </footer>
  );
}