import { Card, Typography } from "@material-tailwind/react";
import React from "react";
import Kuyu from "../assets/kuyu.jpg"

export default function Hidrojeoloji() {
  return (
    <div className="container mx-auto">
      <div className="mx-auto max-w-screen-md py-12">
        <Card className="mb-12 overflow-hidden">
          <img
            alt="nature"
            className="h-[32rem] w-full object-cover object-center"
            src={Kuyu}
          />
        </Card>
        <Typography variant="h2" color="blue-gray" className="mb-2">
          Hidrojeolojik Etüt
        </Typography>
        <Typography color="gray" className="font-normal">
          Hidrojeolojik etüt, suyun yeraltındaki dolaşımını, su taşıyıcı
          formasyonları, su kalitesini ve yeraltı su kaynaklarını incelemek
          amacıyla yapılan önemli bir araştırma ve analiz sürecidir. Yeraltı
          suları, hayati bir doğal kaynak olup tarım, içme suyu, sanayi ve
          enerji üretimi gibi birçok alanda kullanılmaktadır.
        </Typography>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          Hidrojeolojik Etütün Amacı
        </Typography>
        <Typography color="gray" className="font-normal">
          Hidrojeolojik etüt, yeraltı su kaynaklarının verimli ve etkin bir
          şekilde kullanılmasını sağlamak için çeşitli amaçlar taşır: <br />
          <b>Yeraltı Suyu Kaynaklarının Keşfi:</b> Hidrojeolojik etütler,
          potansiyel yeraltı su kaynaklarının tespit edilmesini ve bunların
          kullanılabilirliğinin değerlendirilmesini sağlar.
          <br />
          <b>Su Kalitesinin Belirlenmesi:</b> Yeraltı sularının kimyasal
          bileşimi ve kalitesi, hidrojeolojik etütler sayesinde analiz edilir.
          Bu bilgiler, suyun kullanım amacına uygunluğunu belirlemede kritik rol
          oynar.
          <br />
          <b>Su Akımının Araştırılması:</b> Hidrojeolojik etütler, su taşıyıcı
          formasyonların incelenmesi yoluyla yeraltı su akışını belirlemeye
          yardımcı olur.
          <br />
          <b>Su Kaynaklarının Yönetimi:</b> Etüt sonuçları, yeraltı su
          kaynaklarının sürdürülebilir yönetimini desteklemek için kullanılır.
          Su kaynaklarına etkin bir şekilde erişim sağlanır ve su talebinin
          karşılanmasında planlama ve yönetim süreçleri geliştirilir.
          <br />
        </Typography>
        <Typography variant="h4" color="blue-gray" className="mb-2">
          Hidrojeolojik Etüt Süreci
        </Typography>
        <Typography color="gray" className="font-normal">
          Hidrojeolojik etüt süreci, aşağıdaki temel adımlardan oluşur:
          <br />
          <b>Alan Çalışması ve Veri Toplama:</b> Uzmanlar, saha çalışmaları
          yaparak yeraltı su kaynaklarının olduğu bölgeleri belirler ve veri
          toplama sürecine başlar. Jeolojik yapı, hidrolojik koşullar ve
          hidrojeolojik formasyonlar hakkında detaylı bilgi elde edilir.
          <br />
          <b>Numune Alma ve Analiz: </b> Toplanan numuneler laboratuvarlarda
          analiz edilir. Numunelerin kimyasal ve fiziksel özellikleri belirlenir
          ve su kalitesi hakkında önemli veriler elde edilir.
          <br />
          <b>Modelleme ve Değerlendirme:</b> Toplanan veriler, hidrojeolojik
          modeller oluşturularak değerlendirilir. Bu modeller, su akışı, taşınım
          ve yeraltı suyu seviyesi gibi önemli parametreleri belirlemek için
          kullanılır.
          <br />
          <b>Sonuçların Raporlanması:</b> Etüt sonuçları, detaylı bir rapor
          halinde sunulur. Bu rapor, su kaynaklarının durumu,
          kullanılabilirlikleri ve yönetim önerileri gibi önemli bilgiler
          içerir.
          <br />
          <br />
          Hidrojeolojik etütler, su kaynaklarının verimli ve sürdürülebilir
          kullanımını sağlayan önemli bir araştırma sürecidir. Su kaynakları
          üzerinde yapılan bu çalışmalar, su talebinin arttığı günümüzde su
          kaynaklarının etkin yönetimine katkı sağlar. Hidrojeolojik etütler
          sayesinde yeraltı sularının keşfi ve yönetimi, çevresel ve ekonomik
          açıdan sürdürülebilirlik açısından büyük bir önem kazanır ve su
          kaynaklarından etkin bir şekilde yararlanılması mümkün olur.
        </Typography>
      </div>
    </div>
  );
}
